@import "../../styles/variables.scss";
.signup-container {
  width: 100%;
  .signup-header-row {
    display: flex;
    align-items: center;
    height: 76px;
    background-color: $bg-white;
    box-shadow: 0px 2px 2px $box-shadow;
    justify-content: center;
  }
  .signup-form {
    margin-left: auto;
    height: 691px;
    background-color: $bg-white;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1px;
    .trail-msg {
      font-size: 20px;
      font-weight: bold;
      margin-top: 16px;
    }
    .form-group {
      margin-bottom: 18px;
    }
    .form-btn-group {
      display: flex;
      width: 414px;
      justify-content: flex-end;
    }
    .title-conatiner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 414px;
    }
  }
}
