.cmr-search-box {
  display: flex;
  align-items: center;
  .icon {
    position: relative;
    top: 3px;
    left: 26px;
  }
  input {
    width: 168px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #d3d8dd;
    border-radius: 2px;
    padding-left: 28px;
    font-size: 16px;
    outline: none;
  }
}
