@import "../../styles/variables.scss";
.app-nav-menu-container {
  height: 100%;
  .menu-open-close {
    background: $bg-white;
    height: 40px;
    display: flex;
    align-items: center;
    .logo-icon {
      width: 110px;
      height: 24px;
    }
    .logo-min-icon {
      height: 24px;
      width: 24px;
      padding-bottom: 5px;
    }
    .menu-icon {
      width: 30px;
      height: 30px;
      padding: 0px 5px 0px 12px;
    }
  }
  .nav-container {
    display: flex;
    align-items: center;
    height: 40px;
    .nav-icon {
      width: 30px;
      height: 30px;
      padding-right: 5px;
    }
    .report {
      width: 20px;
      height: 20px;
      padding-left: 6px;
    }
  }
  .openmenu {
    width: 172px;
  }
  .closemenu {
    width: 30px;
  }
  .sidenav {
    height: 100%;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 15px;
    background: $bg-white;
    box-shadow: 4px 4px 22px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .sidenav a {
    text-decoration: none;
    font-size: 13px;
    padding: 0px 10px;
    color: $font-blue;
    font-weight: bold;
    display: block;
    transition: 0.3s;
  }

  .sidenav a:hover {
    color: $font-blue;
    background-color: $bg-app-blue;
  }
  .active-sidenav {
    background-color: $bg-app-blue;
  }
  a::first-letter {
    text-transform: uppercase;
  }
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  .logout-container {
    background-color: $count-green-color;
    color: $font-white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    cursor: pointer;
  }
}
