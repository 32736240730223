@import "../../styles//variables.scss";

.audience-container {
  // background-color: $bg-app-blue;
  margin: 20px;
  .audience-header {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 14px;
      color: $font-grey;
      padding: 10px 23px;
      display: inline-block;
    }
  }

  .audience-list-container {
    min-width: 750px;
    background: $bg-white;
    box-shadow: 1px 1px 5px rgba(0, 5, 58, 0.05);
    border-radius: 4px;
    padding: 15px 20px;
  }
}
