$table-unread-br: #e1e1e1;
$brand-primary: #1755f4;

.spinner {
  animation: rotation 0.9s infinite linear;
  border-radius: 100%;
  content: "";
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 55555;
}

.spinner-large {
  border: 6px solid $table-unread-br;
  border-left-color: $brand-primary;
  height: 60px;
  width: 60px;
}

.spinner-medium {
  height: 40px;
  width: 40px;
  border: 4px solid $table-unread-br;
  border-left-color: $brand-primary;
}

.spinner-small {
  border: 2px solid $table-unread-br;
  border-left-color: $brand-primary;
  height: 20px;
  width: 20px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader-fixed {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  float: right;
}

.loader-label {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  z-index: 5555;
}

.loader-label-large {
  margin-top: 64px;
}

.loader-label-medium {
  margin-top: 40px;
}

.loader-label-small {
  margin-top: 22px;
  font-size: 8px;
}

.spinner-disable-click {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
  z-index: 5555;
  background: black;
  opacity: 0.2;
}
