@import "../../styles/variables.scss";
.header-menu-container {
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  padding-left: 15px;
  height: 40px;
  .left-container {
    display: flex;
    align-items: center;
    .menu-items {
      display: flex;
      align-items: center;
      margin-left: 15px;
      a {
        margin-right: 15px;
        font-size: 18px;
        color: #1e266d;
        height: 40px;
        align-items: center;
        font-weight: bold;
        text-decoration: none;
        line-height: 54px;
        display: flex;
        // background-color: #f4f8fe;
      }
      a:hover {
        color: #1e266d;
        background-color: #f4f8fe;
      }
      .active-sidenav {
        background-color: #f4f8fe;
        border-bottom: 2px solid $count-green-color;
      }
    }
    a::first-letter {
      text-transform: uppercase;
    }
  }
  .logout-container {
    background-color: $count-green-color;
    color: $font-white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    cursor: pointer;
    width: 100px;
    font-size: 18px;
    font-weight: bold;
    .logout-icon {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}
