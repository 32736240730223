@import "../../styles//variables.scss";

.campaign-container {
  background-color: $bg-app-blue;
  display: flex;
  width: 100%;
  flex-direction: column;
  .title {
    font-size: 14px;
    color: $font-grey;
    padding: 10px 23px;
    display: inline-block;
  }
  .campaign-body {
    display: flex;
    flex: 5;
    flex-wrap: wrap;
    .form-btn-group {
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .left-container {
    flex: 2;
    height: 678px;
    background: $bg-white;
    margin: 0px 18px;
    box-shadow: 4px 4px 22px $box-shadow-black;
    border-radius: 4px;
    padding-right: 15px;
    .label {
      padding: 15px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: #000000;
    }
    .total-audiance-container {
      display: flex;
      padding: 0px 15px;
      align-items: center;
      justify-content: flex-end;
      font-size: 18px;
      font-weight: bold;
      span {
        padding-right: 14px;
      }
      .view-all-audience {
        font-weight: normal;
        font-size: 12px;
        text-decoration-line: underline;
        color: $count-green-color;
        cursor: pointer;
      }
    }
    .campaign-sms-container {
      margin: 15px 5px 15px 15px;
      .sms-title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: $black;
        padding-bottom: 10px;
        display: inline-block;
      }
      .sms-text-area {
        min-width: 200px;
        width: 100%;
        height: 260px;
        background: $bg-white;
        border: 1px solid $border-grey;
        border-radius: 4px;
        font-size: 18px;
        padding: 5px 2px;
        outline: none;
      }
    }
  }
  .right-container {
    .email-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: $black;
    }
    flex: 3;
    height: 646px;
    min-width: 300px;
    background: $bg-white;
    box-shadow: 4px 4px 22px $box-shadow-black;
    border-radius: 4px;
    display: flex;
    padding: 16px;
    flex-direction: column;
    .email-subject {
      height: 43px;
      background: $bg-white;
      border: 1px solid $border-grey;
      border-radius: 4px;
      margin: 12px 0px;
      padding: 0px 10px;
      font-size: 18px;
      outline: none;
    }
    .send-campaign-editor {
      max-height: 390px;
      border: 1px solid $border-grey;
      overflow: auto;
    }
    .DraftEditor-root {
      min-height: 390px;
      background: $bg-white;
      border-radius: 4px;
    }
  }
  .campain-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 20px;
    .Mui-checked {
      color: $count-green-color;
    }
    .customize-link {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 15px;
      text-decoration-line: underline;
      cursor: pointer;
      color: #1755f4;
    }
    .customize-info {
      font-size: 12px;
    }
  }
  .chose-operator-modal {
    .modal-content {
      width: 300px;
      height: 160px;
      padding: 25px 56px;
    }
    .modal-body {
      height: 106px;
      padding-top: 30px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      align-content: space-between;
    }
  }
  .chose-operator {
    display: flex;
    .amount-input {
      background: #ffffff;
      border: 1px solid #d3d8dd;
      border-radius: 4px;
      width: 134px;
      height: 34px;
      font-size: 16px;
      margin-left: 15px;
    }
    .amount-operator {
      width: 208px;
      margin-right: 15px;
    }
    .MuiFormControl-root {
      margin-top: 0px;
      width: 142px;
    }
  }
}
