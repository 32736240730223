// break points
$bp-large: 1200px;
$bp-full: 992px;
$bp-medium: 780px;
$bp-mobile: 650px;
$bp-small: 499px;

$bg-grey: #c4c4c4;
$bg-white: #ffffff;
$bg-app-blue: #f3f4f8;
$border-grey: #d3d8dd;
$font-white: #ffffff;
$font-blue: #1e266d;

$box-shadow: #dadada;
$black: #000000;

$count-green-color: #33cccc;
$font-grey: #a1a0a0;
$title-black: black;

$box-shadow-black: rgba(0, 0, 0, 0.06);
$modal-bg-color: rgba(0, 0, 0, 0.3);

$primary-blue: #33cccc;
$light-blue: #cff1f0;

$light-red: #ffdee5;
$red: rgba(251, 5, 44, 0.86);

$button-blue: #1755f4;
$light-button-blue: #1755f491;
