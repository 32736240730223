@import "../../styles/variables.scss";

.crm-modal-container {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  overflow: auto;
  background-color: $modal-bg-color;
  .modal-content {
    margin: auto;
    padding: 64px 74px;
    width: 80%;
    max-width: 1092px;
    background: $bg-white;
    border-radius: 0px;
    box-shadow: 0px 2px 8px 0px $modal-bg-color;
    .modal-header {
      .modal-title {
        color: $title-black;
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .close {
        cursor: pointer;
        float: right;
      }
    }
  }
}
