@import "../../styles//variables.scss";

.overview-container {
  background-color: $bg-app-blue;
  width: 100%;
  .title {
    font-size: 14px;
    color: $font-grey;
    padding: 10px 23px;
    display: inline-block;
  }
  .overview-info-row {
    display: flex;
    justify-content: center;
    .info-card-container {
      margin: 0px 21px;
      .info-icon {
        height: 80px;
        width: 60px;
      }
    }
  }
  .compaign-list-container {
    background-color: $bg-white;
    box-shadow: 1px 1px 5px rgba(0, 5, 58, 0.05);
    border-radius: 4px;
    margin: 20px 20px;
    padding: 15px 20px;
    .list-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      .table-title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: $title-black;
      }
      .list-left-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cmr-search-box {
          margin-right: 15px;
        }
      }
    }

    .campaign-name,
    .view-audience-list {
      font-size: 16px;
      line-height: 20px;
      color: #1755f4;
    }
    .view-audience-list {
      cursor: pointer;
      text-decoration: underline;
    }
    .status-cell {
      border-radius: 6px;
      padding: 2px 10px;
    }
    .ongoing {
      background-color: $light-blue;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $primary-blue;
    }
    .paused {
      background-color: $light-red;
      font-size: 14px;
      line-height: 18px;
      color: $red;
    }
    .completed {
      background-color: $light-button-blue;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $button-blue;
    }
  }
}

.info-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 234px;
  height: 184px;
  background-color: $bg-white;
  box-shadow: 4px 4px 22px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  .info-count {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    color: $count-green-color;
  }
  .info-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $font-grey;
  }
}
