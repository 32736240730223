.spidle-input-text {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 2px;
  height: 42px;
  width: 414px;
  padding: 0px 10px;
}
.spidle-error-message {
  color: red;
}

.app-body-container {
  margin: 0 auto;
}

@media (max-width: 640px) {
  .login-form,
  .signup-form {
    width: 100%;
  }
  .spidle-input-text {
    width: 280px;
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .login-form,
  .signup-form {
    width: 100%;
  }
  .spidle-input-text {
    width: 206px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .login-form,
  .signup-form {
    width: 500px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .login-form,
  .signup-form {
    width: 683px;
  }
}

@media (min-width: 1201px) {
  .login-form,
  .signup-form {
    width: 683px;
  }
}

@media (min-height: 768px) and (max-height: 900px) {
  .login-form,
  .signup-form {
    height: 850px;
  }
}

@media (min-height: 1000px) {
  .login-form,
  .signup-form {
    height: 950px;
  }
}
.ReactTable .rt-thead.-header {
  background: #f4f8fe;
  border: 1px solid #d3d8dd;
  box-shadow: none;
}
.ReactTable .rt-resizable-header {
  padding: 6px 24px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ReactTable .rt-resizer:before {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 3px;
  height: 18px;
  width: 18px;
  color: transparent;
  content: ".";
  background-size: 18px 18px;
  background-repeat: no-repeat;
  opacity: 0.87;
}

.ReactTable .rt-resizable-header-content:after {
  position: absolute;
  right: 8px;
  top: 3px;
  height: 18px;
  width: 18px;
  z-index: 120;
  color: transparent;
  content: ".";
}

.ReactTable .rt-th.-sort-asc .rt-resizer:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMTVWNmw0IDQgMS0xLTYtNi02IDYgMSAxIDQtNHY5eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
}

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOCAzdjkuMTNMNCA4IDMgOWw2IDYgNi02LTEtMS00IDQuMTNWM3oiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
}
