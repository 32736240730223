body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f3f4f8;
  font-family: "Source Sans Pro", Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./fonts/SourceSansPro-Regular.ttf") format("truetype");
}

html,
body {
  height: 100%;
  margin: 0;
}

.app-body-container {
  display: flex;
  max-width: 1366px;
  margin: 0 auto;
  // flex-direction: column;
}
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
